export const STATUS_OPTIONS = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
];


export const PACKAGE_TYPES = [
    { value: "monthly", label: "Monthly" },
    { value: "quartely", label: "Quarterly" },
    { value: "yearly", label: "Yearly" },
];