import React, { useEffect, useState } from "react";
import TableContainer from "../../Components/TableContainer/TableContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { loader, snackbar } from "../../utils";
import { AiTwotoneDelete } from "react-icons/ai";
import { Modal, ModalBody } from "reactstrap";
import { GoInfo } from "react-icons/go";
import {
  addAppBanner,
  deleteAppBanner,
  deleteBannerImg,
  deleteDocs,
  getAllSliderImages,
  uploadDocs,
} from "../../Components/service/admin";

export default function TVSlider() {
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [addModal, setAddModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteImg, setDeleteImg] = useState("");
  const [isValid, setIsValid] = useState(null);
  const handleImageCheck = (event) => {
    const file = event;
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;

        if (Math.abs(ratio - 16 / 9) < 0.01) {
          setIsValid(true);
          //   setErrorMessage('');
          console.log("16:9");
        } else {
          setIsValid(false);
          //   setErrorMessage('The image must have a 16:9 aspect ratio.');
          snackbar.error("The image must have a 16:9 aspect ratio.");
          console.log("not 16:9");
        }

        URL.revokeObjectURL(img.src);
      };
    }
  };

  const getEvent = async () => {
    loader.start();
    const res = await getAllSliderImages();
    let data2 = res?.data?.data;
    // console.log(data)
    let newRes = data2?.map((res, index) => {
      return {
        ...res,
        sno: index + 1,
      };
    });
    console.log(newRes);

    // setAllData(newRes);
    setData(newRes);
    // setTotalData(newRes?.length || 0);
    // paginate(newRes, page);
    loader.stop();
  };

  function paginate(eventData, cpage) {
    const filteredData = eventData.filter(
      (res) =>
        res.Name &&
        typeof res.Name === "string" &&
        res.Name.toLowerCase().includes(searchInput.toLowerCase())
    );
    const startIndex = cpage * rowsPerPage;
    const slicedData = filteredData.slice(startIndex, startIndex + rowsPerPage);
    // setData(slicedData);
  }

  useEffect(() => {
    getEvent();
  }, [rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    paginate(allData, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function addData() {
    if (file?.name == undefined) {
      setError("Please select image");
      return;
    }
    loader.start();
    try {
      if (isValid && isValid !== null) {
        const fileData = new FormData();
        fileData.append("upload", file);
        let imgRes = await uploadDocs(fileData);
        let imgData = imgRes?.data?.data[0];
        let payload = {
          fileData: {
            fileName: imgData?.file_name,
            fileUrl: imgData?.file_url,
          },
          banner_for:'bigSlider'
        };
        let res = await addAppBanner(payload);
        setFile(null);
        setPreview("");
        setAddModal(false);
        setSuccess("New banner added successfully");
        await getEvent();
      } else {
        snackbar.error("The image must have a 16:9 aspect ratio.");
      }
    } catch (err) {
      console.log(err);
      setError("Some error occupide");
    } finally {
      loader.stop();
    }
  }

  async function deleteBanner() {
    loader.start();
    try {
      await deleteDocs([deleteImg])
      let res = await deleteAppBanner(deleteId);
      setDeleteId("");
      setDeleteModal(false);
      setSuccess("Banner deleted successfully");
      await getEvent();
    } catch (err) {
      console.log(err);
      setError("Some error occupide");
    } finally {
      loader.stop();
    }
  }

  function setError(message) {
    snackbar.error(message);
  }

  function setSuccess(message) {
    snackbar.success(message);
  }

  return (
    <>
      <Modal centered isOpen={addModal}>
        <ModalBody>
          <div className="py-4">
            <label className="form-label fs-5 mb-1">Select Slider Image</label>
            <div className="banneruploadContainer">
              {preview !== "" ? (
                <>
                  <div className="bannerUploadimg">
                    <img className="w-100 h-100" src={preview} alt="" />
                  </div>
                </>
              ) : (
                <>
                  <div className="bannerUpload ">16 : 9</div>
                </>
              )}

              <div className="d-flex justify-content-center mt-3">
                <label className="btn btn-sm btn-primary" htmlFor="bannerimg">
                  Select
                </label>
              </div>
            </div>
            <input
              type="file"
              hidden
              id="bannerimg"
              onChange={(e) => {
                setFile(e.target.files[0]);
                if (e.target.files[0]) {
                  handleImageCheck(e.target.files[0]);
                  const previewUrl = URL.createObjectURL(e.target.files[0]);
                  setPreview(previewUrl);
                }
              }}
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            <button
              className="btn btn-sm border"
              onClick={() => {
                setAddModal(false);
                setPreview("");
              }}
            >
              Cancel
            </button>
            <button className="btn btn-sm btn-primary" onClick={addData}>
              Submit
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal centered isOpen={deleteModal}>
        <ModalBody>
          <div className="py-4">
            <div className="d-flex justify-content-center">
              <GoInfo className="text-danger" size={40} />
            </div>
            <div className="text-center mt-3">
              Are you sure want to delete this slider image?
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3">
            <button
              className="btn btn-sm border"
              onClick={() => {
                setDeleteModal(false);
                setDeleteId("");
              }}
            >
              Cancel
            </button>
            <button className="btn btn-sm btn-primary" onClick={deleteBanner}>
              Delete
            </button>
          </div>
        </ModalBody>
      </Modal>
      <h2 className="fw-600">TV Sliders</h2>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary btn-radius px-3"
          onClick={() => {
              setAddModal(true);
          }}
        >
          Add Image
        </button>
      </div>
      <div className="mt-4">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SNo.</TableCell>
                <TableCell>Images</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.map((res, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell
                      className="pointer text-capitalize"
                      style={{ width: "25%" }}
                    >
                      {res?.sno}
                    </TableCell>
                    <TableCell>
                      <img src={res?.fileData?.fileUrl} width="300px" alt="" />
                    </TableCell>
                    <TableCell>
                      <AiTwotoneDelete
                        size={20}
                        className="text-primary pointer"
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteId(res?._id);
                          setDeleteImg(res?.fileData?.fileName)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {/* <TablePagination
                        component="div"
                        count={totalData}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
        </TableContainer>
      </div>
    </>
  );
}
